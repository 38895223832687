<template>
  <div>
    <v-alert>
      <h3>Struktur Organisasi Inspektorat</h3>
    </v-alert>

    <div class="mb-5">
      <CStruktur />
    </div>

  </div>
</template>

<script>
import CStruktur from "@/components/Admin/profil/struktur/struktur";

export default {
  components: {
    CStruktur,
  },

  data: () => ({
    session: "",
  }),
};
</script>
